import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

const useStyles = makeStyles(theme => ({
  table: {
    maxWidth: '100%',
  },
}));

export default memo(({ children }) => {
  const classes = useStyles();
  return (
    <Table className={classes.table} size="small">
      {children}
    </Table>
  )
});