import React from 'react';
import { MONTHS } from '../../helpers/date';
import Select from '@material-ui/core/Select';

const START_YEAR = new Date().getFullYear() - 100;

const optionDate = (
  <>
    <option value="" disabled>วัน</option>
    {
      [...new Array(31)].map((val, index) => {
        return <option key={index} value={index + 1}>{index + 1}</option>
      })
    }
  </>
);

const optionMonth = (
  <>
    <option value="" disabled>เดือน</option>
    {
      MONTHS['initial'].map((val, index) => {
        return <option key={index} value={index + 1}>{val}</option>
      })
    }
  </>
);

const optionYear = (
  <>
    <option value="" disabled>ปี</option>
    {
      [...new Array(100)].map((_, index) => {
        const val = START_YEAR + index;
        return <option key={index} value={val}>{val + 543}</option>
      })
    }
  </>
);

function insertZero(dayOrMonth) {
  return `${(Number(dayOrMonth) < 10 ? '0' : '')}${dayOrMonth}`;
}

export default function BirthDateInput({ value, onChange, defaultValue, ...rest }) {
  const [date, setDate] = React.useState(new Date(defaultValue || `${START_YEAR}-01-01`));

  React.useEffect(() => {
    if (value) {
      setDate(new Date(value))
    }
  }, [value])

  const setBirthDate = (day, month, year) => {
    const currentday = day ? day : date.getDate();
    const currentmonth = month ? month : date.getMonth() + 1;
    const currentyear = year ? year : date.getFullYear();
    const currentDate = `${currentyear}-${insertZero(currentmonth)}-${insertZero(currentday)}`;
    
    const newValidDateFormatDate = new Date(currentDate); //made date valid
    const newValidDateFormatString = `${newValidDateFormatDate.getFullYear()}-${insertZero(newValidDateFormatDate.getMonth()+1)}-${insertZero(newValidDateFormatDate.getDate())}`;
    
    setDate(newValidDateFormatDate)
    onChange(newValidDateFormatString)
  }

  return (
    <>
      <Select
        {...rest}
        value={date ? date.getDate() : ''}
        onChange={(e) => setBirthDate(e.target.value)}
      >
        {optionDate}
      </Select>
      <Select
        {...rest}
        value={date ? date.getMonth() + 1 : ''}
        onChange={(e) => setBirthDate('', e.target.value)}
      >
        {optionMonth}
      </Select>
      <Select
        {...rest}
        value={date ? date.getFullYear() : ''}
        onChange={(e) => setBirthDate('', '', e.target.value)}
      >
        {optionYear}
      </Select>
    </>
  )
}