import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Table from '../Table';
import { navigate } from 'gatsby';
import { getEmoriPoint, getEmoriRelationPersonalHealthPoint } from '../../apis/emori';
import { useDispatch } from 'react-redux';
import { setCorporateId } from '../../actions/partners';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'linear-gradient(91deg, rgba(255, 255, 255, 0.77) 0%, rgba(170, 204, 255, 0.79) 100%)',
    borderRadius: '13px',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
  paper2: {
    background: 'linear-gradient(91deg, rgba(255, 255, 255, 0.77) 0%, rgba(170, 204, 255, 0.79) 100%)',
    borderRadius: '15px',
    display: 'flex',
    padding: '5px 8px 5px 5px',
    alignItems: 'flex-end',
    margin: '20px 0 10px 0',
    flexDirection: 'column-reverse',
  },
  space: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableCell: {
    border: 0,
    padding: '3px',
  },
  topicText1: {
    marginBottom: '3px',
    color: 'white',
    fontSize: '14px',
    marginTop: '2px',
  },
  topicText2: {
    margin: 0,
    color: 'white',
    fontSize: '14px',
  },
  percentage: {
    fontSize: '25px',
    fontWeight: 'bold',
    color: 'white',
  },
  feelingText: {
    fontSize: '14px',
    color: 'white',
  },
  emori: {
    width: '70%',
  },
  noFeeling: {
    width: '50%',
    margin: '10px',
  },
}));

export default ({ relation=false, style=false, partner=false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ point, setPoint] = React.useState(null);
  const [ image, setImage ] = React.useState('');
  const [ feeling, setFeeling ] = React.useState('');
  const [ percentage, setPercentage ] = React.useState('');

  React.useEffect(() => {
    emori();
  }, []);

  const emori = async () => {
    try {
      let res = {};
      if (relation) {
        res = await getEmoriRelationPersonalHealthPoint(relation.room, relation.profile);
      } else {
        res = await getEmoriPoint();
      }
      setPoint(res[Object.keys(res)]);
      checkGroup(res[Object.keys(res)]);
    } catch (e) {}
  }

  const checkGroup = (emoji) => {
    if (emoji.feeling == null) {
      setImage(require(`../../assets/images/no_feeling.svg`));
    } else {
      if (emoji.feeling === 'bad') {
        setImage(require(`../../assets/images/bad.svg`));
        setFeeling('แย่');
      } else if (emoji.feeling === 'good') {
        setImage(require(`../../assets/images/good.svg`));
        setFeeling('ดี');
      } else {
        setImage(require(`../../assets/images/normal.svg`));
        setFeeling('ปานกลาง');
      }
      setPercentage(emoji.percentage);
    }
  }

  const onClickNofeeling = (val) => {
    if (partner) {
      navigate(`/partners/emori/?partnerId=${partner}&tab=${val}`, { replace: true });
    } else if (relation) {
      navigate(`/emori/relation/chooseEmori?profileId=${relation.profile}&tab=${val}`, { replace: true })
    } else {
      navigate(`/emori?tab=${val}`, { replace: true });
    }
  }

  return (
    <div>
      {
        point && !point.feeling ?
          <div className={style ? style.paper2 : classes.paper2} onClick={() => onClickNofeeling(0)}>
            <Grid container spacing={1}>
              <Grid item xs={6} className={classes.space}>
                <img className={classes.noFeeling} src={image} alt='no_feeling' />
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' className={classes.tableCell}>
                        <p className={style ? style.topicText1 : classes.topicText1}>คุณยังไม่ได้บันทึก</p>
                        <p className={style ? style.topicText2 : classes.topicText2}>ความรู้สึกของคุณวันนี้</p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </div>
        :
        <div className={style ? style.paper : classes.paper} onClick={() => onClickNofeeling(1)}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.space}>
              <img className={classes.emori} src={image} alt='feeling_img' />
            </Grid>
            <Grid item xs={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='center' className={classes.tableCell}>
                      <p className={style ? style.topicText1 : classes.topicText1}>คะแนนอารมณ์เฉลี่ย</p>
                      <p className={style ? style.topicText2 : classes.topicText2}>ของคุณในเดือนนี้</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' className={classes.tableCell}>
                      <Typography className={style ? style.percentage : classes.percentage}>{percentage}%</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' className={classes.tableCell}>
                      <Typography className={style ? style.feelingText : classes.feelingText}>{feeling}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          {
            !relation && (
              <ChevronRight />
            )
          }
        </div>
      }
    </div>
  )
}