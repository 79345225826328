import axios from 'axios';
import RequestHelper from './requestHelper';
import { encode } from '../apis';

//
/// Individual
//

export const getEmoriList = () =>
  RequestHelper.get('emori/');

export const postEmoriDiary = (payload, partners, cardId, partnerId) => {
  if (partners) {
    return RequestHelper.post(`corporate/${partnerId}/emori/me/?card=${cardId}`, payload);
  } else {
    return RequestHelper.post(`emori/me/`, payload);
  }
}

export const getEmoriDiary = (year, month, day) =>
  RequestHelper.get(
    `emori/me/daily/?${encode({
      year,
      month,
      day,
    })}`,
  );

export const getEmoriMonthly = (year, month, limit) =>
  RequestHelper.get(
    `emori/me/monthly/?${encode({
      year,
      month,
      limit,
    })}`,
  );

export const getEmoriPoint = (month, year) =>
  RequestHelper.get(
    `/emori/me/healthpoint/?${encode({
      month,
      year,
    })}`,
  );

export const getEmoriAverage = (month, year) =>
  RequestHelper.get(
    `/emori/me/average/?${encode({
      month,
      year,
    })}`,
  );

//
/// Corporate
//

export const getEmoriRelationRoomList = () =>
  RequestHelper.get('emori/relation/room');

export const getEmoriRelationCorporateList = (is_member) =>
  RequestHelper.get(
    `emori/relation/corporate/?${encode({
      is_member,
    })}`,
  );

export const getEmoriRelationIconList = () =>
  RequestHelper.get('emori/relation/room/icon/');
  
export const postEmoriRelationVerify = (corporateId, payload) =>
  RequestHelper.post(`emori/relation/corporate/${corporateId}/verify/`, payload);

export const postEmoriRelationCreateRoom = (payload) =>
  RequestHelper.post(`emori/relation/room/`, payload);

export const patchEmoriRelationRoom = (id, payload) =>
  RequestHelper.patch(`emori/relation/room/${id}/`, payload);

export const getEmoriRelationPositionList = (corporateId, is_leader) =>
  RequestHelper.get(
    `emori/relation/corporate/${corporateId}/position/?${encode({
      is_leader,
    })}`,
  );

export const getEmoriRelationLeaderList = (roomId) =>
  RequestHelper.get(`emori/relation/room/${roomId}/leader/profile/`);

export const postEmoriRelationCreateLeaderProfile = (roomId, payload) =>
  RequestHelper.post(`emori/relation/room/${roomId}/leader/profile/`, payload);

export const patchEmoriRelationLeaderProfile = (roomId, leaderId, payload) =>
  RequestHelper.patch(`emori/relation/room/${roomId}/leader/profile/${leaderId}/`, payload);

export const postEmoriRelationCreateMemberProfile = (roomId, payload) =>
  RequestHelper.post(`emori/relation/room/${roomId}/profile/`, payload);

export const patchEmoriRelationMemberProfile = (roomId, memberId, payload) =>
  RequestHelper.patch(`emori/relation/room/${roomId}/profile/${memberId}/`, payload);

export const removeProfileEmoriRelation = (roomId, payload) =>
  RequestHelper.bulkDelete(`emori/relation/room/${roomId}/profile/`, payload);

export const getEmoriRelationProfileList = (roomId, limit, search, page) =>
  RequestHelper.get(
    `emori/relation/room/${roomId}/profile/?${encode({
      limit,
      search,
      offset: page ? (page - 1) * limit : 0,
    })}`,
  );

// Personal

export const postEmoriRelationDiary = (roomId, memberId, payload) =>
  RequestHelper.post(`emori/relation/room/${roomId}/profile/${memberId}/emori/`, payload);

export const getEmoriRelationDiary = (roomId, memberId, year, month, day) =>
  RequestHelper.get(
    `emori/relation/room/${roomId}/profile/${memberId}/emori/daily?${encode({
      year,
      month,
      day,
    })}`,
  );

export const getEmoriRelationMonthly = (roomId, memberId, year, month, limit) =>
  RequestHelper.get(
    `emori/relation/room/${roomId}/profile/${memberId}/emori/monthly?${encode({
      year,
      month,
      limit,
    })}`,
  );

export const getEmoriRelationAverage = (roomId, memberId, month, year) =>
  RequestHelper.get(
    `emori/relation/room/${roomId}/profile/${memberId}/emori/average?${encode({
      month,
      year,
    })}`,
  );

export const getEmoriRelationPersonalHealthPoint = (roomId, memberId, month, year) =>
  RequestHelper.get(
    `emori/relation/room/${roomId}/profile/${memberId}/emori/healthpoint?${encode({
      month,
      year,
    })}`,
  );

// Room

export const getEmoriRelationHealthPoint = (roomId, month, year) =>
  RequestHelper.get(
    `emori/relation/room/${roomId}/healthpoint/?${encode({
      month,
      year,
    })}`,
  );

export const getEmoriRoomRelationAverage = (roomId, month, year) =>
  RequestHelper.get(
    `emori/relation/room/${roomId}/average/?${encode({
      month,
      year,
    })}`,
  );