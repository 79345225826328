import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles(theme => ({
  cell: {
    border: 0,
    padding: '4px 4px',
  },
}));

export default memo(({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <TableCell {...rest} className={classes.cell}>{children}</TableCell>
  )
});
