export const checkGroup = (emoji) => {
  let image = '';
  let feeling = '';
  let percentage = 0;
  if (emoji.feeling == null) {
    image = (require(`../assets/images/no_feeling.svg`));
  } else {
    if (emoji.feeling === 'bad') {
      image = (require(`../assets/images/bad.svg`));
      feeling = 'แย่';
    } else if (emoji.feeling === 'good') {
      image = (require(`../assets/images/good.svg`));
      feeling = 'ดี';
    } else {
      image = (require(`../assets/images/normal.svg`));
      feeling = 'ปานกลาง';
    }
    percentage = emoji.percentage;
  }
  const params = { 'image': image, 'feeling': feeling, 'percentage': percentage }
  return params;
}

export const baseProfileData = () => {
  return 'https://service.moronline.me/static/images/avatar.png'
}
