import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { createForm } from 'rc-form';
import { useSelector } from 'react-redux';
import Table from '../../../components/Table';
import Cell from '../../../components/Table/cell';
import Select from '@material-ui/core/Select';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Layout from '../../../components/v2/Layout';
import { Paper, TableCell } from '@material-ui/core';
import { getEmoriRelationPositionList, patchEmoriRelationMemberProfile, postEmoriRelationCreateMemberProfile, removeProfileEmoriRelation } from '../../../apis/emori';
import { getUrlParams } from '../../../helpers/location';
import BirthDateInput from '../../../components/BirthDateInput';
import { createEmoriProfileByIdSelector } from '../../../reducers/emori';
import SummaryMonthlyEmori from '../../../components/SummaryMonthlyEmori';
import SkeletonLoader from '../../../components/SkeletonLoader';
import ProfileSelector from '../../../components/ProfileSelector';
import { baseProfileData } from '../../../helpers/emori';

const useStyles = makeStyles(theme => ({
  titleInput: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  tableSpace: {
    borderSpacing: '0px 20px',
    borderCollapse: 'separate'
  },
  emori: {
    width: '70%',
  },
  space: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableCell: {
    border: 0,
    padding: '3px',
  },
  topicText1: {
    marginBottom: '3px',
    color: '#858484',
    fontSize: '14px',
    marginTop: '2px',
  },
  topicText2: {
    margin: 0,
    color: '#858484',
    fontSize: '14px',
  },
  percentage: {
    fontSize: '25px',
    fontWeight: 'bold',
    color: '#858484',
  },
  feelingText: {
    fontSize: '14px',
    color: '#858484',
  },
  paper: {
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '40px',
    padding: '15px'
  },
  paper2: {
    background: 'white',
    display: 'flex',
    alignItems: 'flex-end',
    margin: '20px 0 10px 0',
    flexDirection: 'column-reverse',
    borderRadius: '40px',
    padding: '15px'
  },
}));

const ProfileAdminEmori = ({ form, readOnly, loading, location }) => {
  const classes = useStyles();
  const { validateFields, getFieldDecorator, getFieldError } = form;
  const [ newValue, setNewValue] = React.useState('');
  const [ position, setPosition ] = React.useState([]);
  const { corporate, room } = useSelector(state => state.emori);
  const params = getUrlParams(location.search);
  const { id } = params;
  const profile = useSelector(state => createEmoriProfileByIdSelector(state, id));
  const [ imageURLs, setImageURLs ] = React.useState(baseProfileData);
  const [ imageFile, setImageFile ] = React.useState(null);
  const [ isImgChange, setIsImgChange ] = React.useState(false);

  React.useEffect(() => {
    emori();
  }, []);

  const emori = async () => {
    try {
      const member = await getEmoriRelationPositionList(corporate, 'false');
      setPosition(member.results);
      if (profile.image) {
        setImageURLs(profile.image);
      }
    } catch (e) {}
  }

  const onSubmit = async (payload) => {
    const updateProfileImagePayload = new FormData();
    updateProfileImagePayload.append(
      'image', imageFile
    );

    await patchEmoriRelationMemberProfile(room, id, payload);
    if (isImgChange) {
      await patchEmoriRelationMemberProfile(room, id, updateProfileImagePayload);
      setIsImgChange(false);
    }
    await navigate(`/emori/relation/room?id=${room}`, { replace: true })
  }

  const checkChange = () => {
    validateFields((error, value) => {
      if (!error) {
        const payload = {
          name: value.name,
          position: value.position,
          nickname: value.nickname,
          birthday: value.birthday,
        }
        onSubmit(payload);
      }
    });
  }

  const handleDelete = async () => {
    try {
      const delPro = [];
      delPro.push(id);
      const payload = {
        'profile_ids' : delPro
      }
      await removeProfileEmoriRelation(room, payload);
      await navigate(`/emori/relation/room?id=${room}`, { replace: true })
    } catch (e) {}
  }

  if (!profile) return false;

  return (
    <Layout bg='bg3' title='แก้ไขโปรไฟล์'>
      {
        !profile ? (
          <SkeletonLoader />
        ) : (
          <SummaryMonthlyEmori relation={{room: room, profile: id}} style={classes}/>
        )
      }
      <Paper style={{ borderRadius: '40px', padding: '30px', marginTop: '8px'}}>
        <Table classes={{ root: classes.tableSpace }}>
          <TableBody>
            <TableRow>
              <Cell style={{ minWidth: '100px' }}><span className={classes.titleInput}>ชื่อ</span></Cell>
              <Cell>
                {
                  getFieldDecorator('name', {
                    rules: [{ required: true }],
                    initialValue: profile.name
                  })(
                    <Input
                      disabled={readOnly}
                      fullWidth
                      error={getFieldError('name')}
                    />
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell style={{ minWidth: '100px' }}><span className={classes.titleInput}>ชื่อเล่น</span></Cell>
              <Cell>
                {
                  getFieldDecorator('nickname', {
                    rules: [{ required: true }],
                    initialValue: profile.nickname
                  })(
                    <Input
                      disabled={readOnly}
                      fullWidth
                      error={getFieldError('nickname')}
                    />
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell style={{ minWidth: '100px' }}><span className={classes.titleInput}>วันเกิด</span></Cell>
              <Cell>
                {
                  getFieldDecorator('birthday', {
                    rules: [{ required: true }],
                    initialValue: profile.birthday,
                    getValueFromEvent: function (e) { return e }
                  })(
                    <BirthDateInput
                      native
                      error={getFieldError('birthday')}
                    />
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell style={{ minWidth: '100px' }}><span className={classes.titleInput}>ตำแหน่ง</span></Cell>
              <Cell>
                {
                  getFieldDecorator('position', {
                    rules: [{ required: true }],
                    initialValue: profile.position
                  })(
                    <Select
                      disabled={readOnly}
                      className={classes.textInput}
                      fullWidth
                      native
                      error={getFieldError('position')}
                    >
                      <option value=''>เลือก</option>
                      {position.map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell><span className={classes.titleInput}>รูปโปรไฟล์</span></Cell>
              <Cell>
                <ProfileSelector
                  stateCurrentImgURLs={imageURLs}
                  stateIsImgChange={isImgChange}
                  onIsImgChange={setIsImgChange}
                  onGetImgFile={setImageFile}
                  onGetImgUrls={setImageURLs}
                  usePreview={true}
                />
              </Cell>
            </TableRow>
          </TableBody>
        </Table>
        <Box mt={3}>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading}
            onClick={() => checkChange()}
            style={{ borderRadius: '20px' }}
          >
            บันทึก
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            fullWidth
            variant='outlined'
            color='primary'
            disabled={loading}
            onClick={() => handleDelete()}
            style={{ borderRadius: '20px' }}
          >
            ลบโปรไฟล์นี้
          </Button>
        </Box>
      </Paper>
    </Layout>
  );
};

export default createForm()(ProfileAdminEmori);